import React, { Fragment, useEffect, useState } from "react";
import { innovateKey } from "~/constant";
import axios from "axios";
import { Spin, Typography } from "antd";
import { TranslationOutlined } from "@ant-design/icons";
import Md from "~/components/md/md";
const { Title, Paragraph } = Typography;

const keys = [
  {
    key: "tech_field",
    cn: "技术领域",
    en: "Technical Field",
  },
  {
    key: "background",
    cn: "背景技术",
    en: "Background",
  },
  {
    key: "summary",
    cn: "发明内容",
    en: "Summary",
  },
  {
    key: "abstract",
    cn: "摘要",
    en: "Abstract",
  },
  {
    key: "claim",
    cn: "权利要求",
    en: "Claim",
  },
];

export default function Generate() {
  const [patentData, setPatentData] = useState();
  const [spinning, setSpinning] = React.useState(false);
  const [lang, setLang] = useState("en");
  useEffect(() => {
    setSpinning(true);
    const info = JSON.parse(localStorage.getItem(innovateKey));
    if (info) {
      axios({
        url: `https://www.knowledgeworks.chat/api/innovation/patent/fulltext`,
        headers: { "Content-Type": "application/json" },
        method: "post",
        data: {
          patent_id: "",
          abstract: "",
          idea: info.idea,
        },
      }).then(({ data }) => {
        console.log(data);
        setPatentData(data);
        setSpinning(false);
      });
    }
  }, []);
  return (
    <div style={{ paddingTop: 60 }}>
      <TranslationOutlined
        style={{ fontSize: 17, float: "right", margin: "10px 20px 0" }}
        onClick={() => {
          lang == "en" ? setLang("cn") : setLang("en");
        }}
      />
      <Typography style={{ padding: 40 }}>
        {patentData &&
          keys.map((key) => {
            return (
              <Paragraph key={key.en}>
                <Title level={3}>{lang == "en" ? key.en : key.cn}</Title>
                <Paragraph>
                  <Md
                    md={patentData[lang == "en" ? key.key : key.key + "_CHN"]}
                  />
                </Paragraph>
              </Paragraph>
            );
          })}
      </Typography>
      <Spin
        spinning={spinning}
        fullscreen
        tip={
          lang == "en"
            ? "Generating patent... Please wait patiently as this process may take about 10 minutes."
            : "正在生成专利中，请耐心等待。此过程可能需要约10分钟。"
        }
      />
    </div>
  );
}
