import React from "react"
import {Route, Routes} from "react-router-dom"
import Home from "./pages/home/home"
import Redirect from "./pages/redirect/index"
import Search from "./pages/search/search"
import Payed from "./components/pay/payed"
import Innovate from "./pages/innovate/innovate";
import Generate from "./pages/generate/generate"

export default function Router({user, setLoginOpen}) {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home user={user} setLoginOpen={setLoginOpen}/>}/>
        <Route path="/search/:key" element={<Search user={user} example={false}/>}/>
        <Route path="/example/:key" element={<Search user={user} example={true}/>}/>
        <Route path="/innovate" element={<Innovate/>}/>
        <Route path="/generate" element={<Generate/>}/>
        <Route path="/payed" element={<Payed/>}/>
        <Route path="/redirect/:key" element={<Redirect/>}/>
        <Route path="*" element={<Home user={user}/>}></Route>
      </Routes>
    </React.Fragment>
  );
}
