import React, { useEffect, useState } from "react";
import { Modal, Radio, Space, Checkbox, message, Button } from "antd";
import styles from "./payModal.scss";
import { get } from "~/request";
import { ossServer } from "~/constant";
import { payWithAlipay } from "~/components/pay/alipay";
import { payWithPaypal } from "~/components/pay/paypal";
import Disclaimer from "../disclaimer/disclaimer";
import { payWithFree } from "./free";

export default function PayModal({
  visible,
  setVisible,
  payItem,
  postData,
  onSuccess,
  lang,
}) {
  const [payMethod, setPayMethod] = useState(1);
  const [price, setPrice] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(false);

  const onPay = () => {
    if (checked) {
      setVisible(false);
      if (payMethod === 1) {
        payWithAlipay({
          data: {
            ...postData,
            payItem,
          },
          onSuccess,
          keep: payItem === 3 || payItem === 4,
        });
      } else if (payMethod === 2) {
        payWithPaypal({
          data: {
            ...postData,
            payItem,
          },
          onSuccess,
        });
      } else if (payMethod === 3) {
        payWithFree({
          data: {
            ...postData,
            payItem,
          },
          onSuccess,
        });
      }
    } else {
      message.warning("Please check User License.");
    }
  };

  const getPrice = () => {
    get({
      url: `/price?payItem=${payItem}`,
      onSuccess: (data) => {
        setPrice(data);
      },
    });
  };

  useEffect(() => {
    getPrice();
  }, []);

  const permission = () => {
    if (checked) {
      setChecked(false);
    } else {
      setShowModal(true);
    }
  };
  const onDisclaimerConfirmed = () => {
    setChecked(true);
    setShowModal(false);
  };

  return (
    <Modal
      title="Please select a payment method"
      open={visible}
      onOk={onPay}
      footer={
        <Button type="primary" disabled={!checked} onClick={onPay}>
          OK
        </Button>
      }
      onCancel={() => setVisible(false)}
    >
      {price && (
        <Radio.Group
          onChange={({ target: { value } }) => setPayMethod(parseInt(value))}
          value={payMethod}
        >
          <Space direction="vertical">
            <Radio value={1}>
              <div className={styles.radioItem}>
                <img src={`${ossServer}/web/Alipay_logo.jpg`} alt="" />
                Alipay(¥{(price.cny / 100).toFixed(2)})
              </div>
            </Radio>
            <Radio value={2}>
              <div className={styles.radioItem}>
                <img src={`${ossServer}/web/favicon.ico`} alt="" />
                Paypal(${(price.usd / 100).toFixed(2)})
              </div>
            </Radio>
            {/* <Radio value={3}>
              <div className={styles.radioItem}>
                Free for a limited time&nbsp;
                <span style={{ textDecoration: "line-through" }}>
                  ${(price.usd / 100).toFixed(2)}
                </span>
              </div>
            </Radio> */}
            <Checkbox checked={checked} onChange={permission}>
              User permission
            </Checkbox>
            <Disclaimer
              isModalOpen={showModal}
              onOk={onDisclaimerConfirmed}
              onCancel={() => {
                setShowModal(false);
              }}
              type="pay"
              lang={lang && lang}
            />
          </Space>
        </Radio.Group>
      )}
    </Modal>
  );
}
