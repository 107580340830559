import React from "react"
import {useParams} from "react-router-dom"
import Paper from "../paper/paper"
import Patent from "../patent/patent"
import {doiReg, patentReg} from "../../constant"

export default function Search({user, example}) {
  const params = useParams()
  const key = decodeURIComponent(params.key)

  if (doiReg.test(key)) {
    return <Paper user={user} example={example} doi={key}/>
  } else if (patentReg.test(key)) {
    return <Patent user={user} example={example} patent={key}/>
  } else {
    window.location.href = "/"
  }
}